<script>
  import { clickOutside } from '@client/actions/click-outside';

  export let items = [];
  export let source = null;
  export let show = false;
  export let classNames = '';
  export let horizontal = false;
  export let onHide = () => {};

  const onClickOutside = () => (show = false);

  $: if (show === false) {
    onHide();
  }
</script>

<div class="menu-wrapper" use:clickOutside={{ onClickOutside }}>
  <slot />
  <div class={`menu ${classNames}`} class:show class:horizontal>
    <ul>
      {#each items as item}
        <li>
          {#if item.type === 'link'}
            <a href={item.href} target={item.target}>{item.text}</a>
          {:else if item.type === 'icon'}
            <button on:click={(e) => item.onClick(e, source)} disabled={item.disabled}>
              <i class={item.iconClass} />
            </button>
          {:else}
            <button on:click={(e) => item.onClick(e, source)} disabled={item.disabled}>{item.text}</button>
          {/if}
        </li>
      {/each}
    </ul>
  </div>
</div>

<style lang="scss" src="./menu.scss"></style>
