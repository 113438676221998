<script>
  export let checked = false;
  export let ref = null;
  export let disabled = null;
</script>

<label class="switch">
  <input bind:this={ref} type="checkbox" bind:checked aria-disabled={disabled} {disabled} />
  <span class="slider" />
</label>

<style lang="scss" src="./switch.scss"></style>
