import { APITransport } from '@client/transports/api';

export const UsersService = {
  getAll(query = {}) {
    return APITransport.get({
      path: '/v1/users',
      query
    });
  },

  get() {
    return APITransport.get({
      path: '/v1/users/me'
    });
  },

  create({ domain, email, imageUrl, name, user }) {
    return APITransport.post({
      path: '/v1/users',
      body: {
        domain,
        email,
        imageUrl,
        name,
        user
      }
    });
  },

  login(code) {
    return APITransport.post({
      path: '/auth/google',
      query: {
        code
      }
    });
  },

  logout() {
    return APITransport.post({
      baseURL: '',
      path: '/logout'
    });
  }
};
