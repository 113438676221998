<script>
  import { relativeTimestamp } from '@client/utils/dates';

  export let row = {};
</script>

<div>{row.title}</div>

<div>
  <small class="source"><a href={row.source} target="_blank">{row.source}</a></small>
</div>

{#if row.lastViewed}
  <small class="block mt-sm muted">Last visited {relativeTimestamp(row.lastViewed)}</small>
{/if}

<style>
  .source {
    word-break: break-all;
  }
</style>
