<script>
  import { onMount } from 'svelte';

  export let value = '';
  export let ref = null;
  export let disabled = null;
  export let pattern = null;
  export let valid = false;

  const regexp = new RegExp(pattern);

  onMount(() => {
    ref.addEventListener('keyup', () => {
      valid = pattern ? regexp.test(value) : true;
    });
  });
</script>

<input bind:this={ref} aria-disabled={disabled} on:input on:change on:focus on:blur on:keyup on:keypress on:keydown bind:value {disabled} {valid} {...$$props} />

<style lang="scss" src="./input.scss"></style>
