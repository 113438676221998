<script>
  export let index = 0;
  export let steps = [];
  export let validate = (/*index, fields*/) => true;
  export let onComplete = () => {};
  export let showIndicator = true;
  export let showNavigation = true;

  let clazz = '';
  export { clazz as class };
  let current = null;

  $: maxSteps = steps ? steps.length : 0;
  $: hasPrevStep = index > 0;
  $: hasNextStep = index < maxSteps;

  $: {
    if (steps[index]) {
      current = steps[index];
    } else {
      current = null;
    }
  }

  const prevStep = () => {
    if (hasPrevStep) {
      index--;
    }
  };

  const nextStep = () => {
    if (validate(index) && hasNextStep) {
      index++;
    }
  };

  const handleFinish = () => onComplete();
</script>

<div class="wizard {clazz}">
  <div>
    {#if current}
      <svelte:component this={current} {prevStep} {nextStep} />
    {/if}
  </div>
  <footer>
    {#if showNavigation}
      {#if hasNextStep}
        <button on:click={prevStep}>&lt;&lt;</button>
        <button on:click={nextStep}>&gt;&gt;</button>
      {:else}
        <button on:click={handleFinish}>FINISH</button>
      {/if}
    {/if}
    {#if showIndicator}
      <div class="indicator">
        {#each Array(maxSteps) as _, i}
          <div class="indicator-item" class:active={i === index} />
        {/each}
      </div>
    {/if}
  </footer>
</div>

<style lang="scss" src="./wizard.scss"></style>
