/**
 * Recursively process configuration object from nested to kebab-case
 * @name convertToCSSVars
 * @param {Object} config
 *
 */
export const createCSSVars = (config) => {
  const result = {};

  const recurse = (config, current) => {
    for (let prop in config) {
      const value = config[prop];
      const key = (current ? `${current}-${prop}` : prop)
        .replace(/-_$/g, '')
        .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
        .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
        .toLowerCase();

      if (value && typeof value === 'object') {
        recurse(value, key);
      } else {
        result[key] = value;
      }
    }
  };

  recurse(config);

  return Object.keys(result)
    .map((key) => `--${key}: ${result[key]};`)
    .join('\n\t');
};
