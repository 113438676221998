import Axios from 'axios';
// import { logEvent } from '@client/utils/logger';

export const TransportUtils = {
  axiosCreate(options) {
    const axios = Axios.create(options);

    axios.interceptors.request.use(
      (config) => ({
        ...config,
        t0: performance.now()
      }),
      (error) => Promise.reject(error)
    );

    const logEvents = (config) => {
      const value = performance.now() - config.t0;
      const label = `${config.method} ${config.url}`;

      console.log('Request Latency', label, value);
      console.log('Request Success', label);

      // logEvent({ name: 'latency', data: { [label]: value } });
    };

    axios.interceptors.response.use(
      (response) => {
        logEvents(response.config);
        return response;
      },
      (error) => {
        logEvents(error.config);
        return Promise.reject(error);
      }
    );

    return axios;
  }
};
