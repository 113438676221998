<script>
  export let type = '';

  $: classes = `notification ${type}`;
</script>

<div class={classes}>
  <slot></slot>
</div>

<style lang="scss" src="./notification.scss"></style>
