<script>
  import merge from 'lodash/merge';

  import { Chart } from '../chart';

  export let data = [];
  export let height = '50px';
  export let width = 'auto';
  export let options = {};

  const defaultOptions = {
    responsive: true,
    legend: {
      display: false
    },
    elements: {
      line: {
        borderColor: 'rgb(0, 135, 255)',
        borderWidth: 1
      },
      point: {
        radius: 0
      }
    },
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        display: false
      },
      x: {
        display: false
      }
    }
  };

  $: sparkOptions = merge({}, defaultOptions, options);
</script>

<Chart type="line" {data} bind:options={sparkOptions} {height} {width} />
