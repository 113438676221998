<script>
  import { getContext, onMount } from 'svelte';
  import { useLocation } from 'svelte-navigator';

  import { userStore } from '@client/stores/user';
  import { UsersService } from '@client/services/users';
  import { processLoggedInUser } from '@client/app/bootstrap';
  import { appStore } from '@client/stores/app';

  const { googleOauthRedirectUrl, googleClientId } = getContext('config');

  const location = useLocation();

  let loaded = false;
  let error = false;

  const params = {
    redirect_uri: googleOauthRedirectUrl,
    client_id: googleClientId,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    scope: ['https://www.googleapis.com/auth/userinfo.profile', 'https://www.googleapis.com/auth/userinfo.email'].join(
      ' '
    )
  };

  const qs = new URLSearchParams(params);
  const oauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?${qs.toString()}`;

  onMount(async () => {
    const queryParams = new URLSearchParams($location.search);
    const code = queryParams.get('code');

    let user = {};

    if (code) {
      user = await UsersService.login(code);
    } else {
      user = await UsersService.get();
    }

    if (!user.error) {
      $userStore = processLoggedInUser(user);
      $appStore.authenticated = true;
    } else {
      if (user.error.status === 403 && user.error.message.includes('Personal')) {
        error = true;
      }
    }

    loaded = true;
  });
</script>

<div class="page">
  <div class="login" class:hide={!loaded}>
    {#if !error}
      <div class="beta">BETA</div>
      <img src="images/colinks.svg" height="40" alt="co/links logo" />
      <p>You can access co/links using a corporate Gmail account.</p>
      <a class="btn btn-primary btn-secondary" href={oauthUrl}>Sign-In</a>
    {:else}
      <img src="https://dysfunc.s3.amazonaws.com/no-chance.gif" alt="No chance!" />
      <p>Only verified corporate Gmail accounts can access co/links.</p>
    {/if}
  </div>
</div>

<style lang="scss" src="./login.scss"></style>
