<script>
  import { format } from 'svelte-i18n';

  import { Button } from '@client/components/button';

  const reload = () => window.location.reload();

  $: extensionInstalled = 'ontouchstart' in window ? true : document.body.dataset.colinks;
</script>

<div class="wizard-page done">
  <h1>{$format('label.YOUR_LINK_WAS_CREATED')}</h1>
  <img src="/images/done.svg" alt="Finished creating link" />

  {#if !extensionInstalled}
    <p>{$format('label.INSTALL_EXTENSION_ACCESS_LINKS')}</p>
    <p>{@html $format('label.POST_EXTENSION_INSTALL')}</p>
    <a
      class="btn btn-secondary mb-lg"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/colinks/pciabmbjggmolgclddfmcfbipibfeapp?hl=en&authuser=0"
      alt="Download colinks extension"
    >
      {$format('label.INSTALL')}
    </a>
  {:else}
    <p>{$format('label.SIGN_INTO_EXTENSION')}</p>
    <p>{@html $format('label.POST_EXTENSION_INSTALL')}</p>
  {/if}

  <Button on:click={reload}>
    {$format('label.OK_IM_FINISHED')}
  </Button>
</div>

<style lang="scss" src="../getting-started.scss"></style>
