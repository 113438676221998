export const QueryParams = {
  delete(...args) {
    const params = new URLSearchParams(location.search);

    args.forEach((key) => params.delete(key));

    window.history.pushState({}, '', `${location.pathname}?${params}`);
  },
  get(key) {
    const params = new URLSearchParams(location.search);
    let result = '';

    try {
      result = JSON.parse(params.get(key));
    } catch (e) {
      result = params.get(key);
    }

    return result;
  },
  set(name, value) {
    const params = new URLSearchParams(location.search);

    if (typeof name === 'object') {
      Object.keys(name).forEach((key) => params.set(key, JSON.stringify(name[key])));
    } else {
      params.set(name, value);
    }

    window.history.pushState({}, '', `${location.pathname}?${params}`);
  }
};
