import { base } from './base';
// import { azure } from './azure';
import { colinks } from './colinks';
import { minimal } from './minimal';

const themes = {
  // azure,
  colinks,
  minimal
};

export { base, themes };
