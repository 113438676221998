import { APITransport } from '@client/transports/api';

export const BookmarksService = {
  getAll(query) {
    return APITransport.get({
      path: '/v1/bookmarks',
      query
    });
  },

  create({ user, link }) {
    return APITransport.post({
      path: '/v1/bookmarks',
      body: { link, user }
    });
  },

  delete({ user, link }) {
    return APITransport.delete({
      path: '/v1/bookmarks',
      body: { link, user }
    });
  }
};
