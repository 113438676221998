<script>
  import { onDestroy } from 'svelte';
  import { fade } from 'svelte/transition';

  import { getNotificationsContext } from './notification.context';

  export let notification = {};

  const { removeNotification } = getNotificationsContext();

  const { id, autohide, header, text, component, type = 'default' } = notification;

  const onRemove = () => removeNotification(id);

  let timeout = null;

  if (autohide) {
    timeout = setTimeout(onRemove, autohide);
  }

  const icons = {
    success: 'links-icon-check',
    error: 'links-icon-x',
    warning: 'links-icon-alert-triangle',
    info: 'links-icon-info'
  };

  onDestroy(() => {
    if (autohide && timeout) {
      clearTimeout(timeout);
    }
  });
</script>

{#if component}
  <svelte:component this={component} {notification} {onRemove} />
{:else}
  <div class={`notification ${type}`} class:compact={!header} role="status" aria-live="polite" in:fade out:fade>
    {#if header}
      <div class="header">
        <span>{@html header}</span>

        {#if !autohide}
          <button class="close" on:click={onRemove} aria-label="delete notification">
            <i class="links-icon-x" />
          </button>
        {/if}
      </div>
    {/if}
    <div class="body">
      {#if !header && type && icons[type]}
        <i class={icons[type]} />
      {/if}

      <span>{@html text}</span>

      {#if !autohide && !header}
        <button class="close" on:click={onRemove} aria-label="delete notification">
          <i class="links-icon-x" />
        </button>
      {/if}
    </div>
  </div>
{/if}

<style lang="scss" src="./notification.scss"></style>
