const bootstrap = window.__BOOTSTRAP__;

delete window.__BOOTSTRAP__;

export const getBootstrap = () => bootstrap;

export const updateBootstrap = (key, value) => (bootstrap[key] = value);

export const processLoggedInUser = (user) => {
  user.bookmarks = new Set(user.bookmarks);
  user.links = new Set(user.links);

  updateBootstrap('domain', user.domain);

  return user;
};
