export const minimal = {
  meta: {
    name: 'Minimal'
  },
  layout: {
    content: {
      border: {
        left: '1px solid var(--border-color)'
      },
      boxShadow: '0'
    }
  },
  border: {
    color: 'var(--color-gray-200)'
  },
  sidebar: {
    background: {
      color: 'var(--color-white)'
    },
    border: '1px solid var(--border-color)',
    box: {
      shadow: '0'
    },
    divider: {
      color: 'var(--border-color)'
    },
    header: {
      border: {
        bottom: '1px solid var(--sidebar-divider-color)'
      },
      height: '110px',
      imageBackground: 'url(https://colinks.s3.amazonaws.com/colinks.svg) no-repeat center center',

      badge: {
        backgroundColor: 'var(--border-color)',
        color: 'var(--color-gray-700)'
      },

      create: {
        border: {
          _: '1px solid var(--border-color)'
        },
        boxShadow: '0 1px 4px rgba(0, 0, 0, .05)'
      }
    },

    section: {
      heading: {
        color: {
          _: 'var(--color-gray-600)',
          hover: 'var(--color-gray-900)',
          active: 'var(--color-gray-900)'
        },
        toggle: {
          icon: {
            color: {
              active: 'var(--color-gray-800)'
            }
          }
        },
        icon: {
          background: {
            color: {
              active: 'var(--color-blue-400)'
            }
          },
          color: {
            _: 'var(--color-gray-900)',
            active: 'var(--color-white)'
          }
        }
      },
      items: {
        border: {
          left: '2px solid var(--border-color)'
        },
        active: {
          item: {
            color: 'var(--color-gray-900)'
          }
        }
      },
      item: {
        backgroundColor: {
          active: 'var(--color-blue-400)',
          hover: 'var(--color-gray-100)'
        },
        color: {
          _: 'var(--color-gray-700)',
          hover: 'var(--color-gray-900)',
          active: 'var(--color-white)'
        }
      }
    },
    user: {
      background: {
        color: 'var(--color-white)'
      },
      border: {
        top: '1px solid var(--border-color)'
      },
      name: {
        color: 'var(--color-gray-900)'
      },
      trigger: {
        backgroundColor: 'var(--color-gray-100)',
        color: 'var(--color-gray-600)'
      }
    }
  }
};
