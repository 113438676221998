<script>
  import { Doughnut, Line, Bar, Pie } from 'svelte-chartjs';
  import { generateData, generateChartsConfig } from '@client/utils/charts';

  export let type = null;
  export let label = null;
  export let title = null;
  export let subtitle = null;
  export let data = generateData();
  export let options = {};
  export let height = '300px';
  export let width = 'auto';

  $: chartOptions = generateChartsConfig(type, options);
</script>

<div class="chart">
  {#if label}
    <small>{@html label}</small>
  {/if}
  {#if title}
    <span>{@html title}</span>
  {/if}
  {#if subtitle}
    <small>{@html subtitle}</small>
  {/if}

  <div class="chart-container" style="height: {height}; width: {width};" on:touchstart|preventDefault>
    {#if type === 'line'}
      <Line {data} bind:options={chartOptions} />
    {/if}
    {#if type === 'pie'}
      <Pie {data} bind:options={chartOptions} />
    {/if}
    {#if type === 'bar'}
      <Bar {data} bind:options={chartOptions} />
    {/if}
    {#if type === 'doughnut'}
      <Doughnut {data} bind:options={chartOptions} />
    {/if}
  </div>
</div>

<style lang="scss" src="./chart.scss"></style>
