<script>
  import { format, locale, locales } from 'svelte-i18n';
  import { Col, Container, Row } from 'sveltestrap';

  import { currentThemeName, themes, setTheme } from '@client/components/theme';
  import { getNotificationsContext } from '@client/components/notifications';
  import { Loader } from '@client/components/loader';

  import { LOCALES } from '@client/enums/locales';

  const { addNotification } = getNotificationsContext();

  let loaded = true;

  const onThemeUpdate = (e) => {
    const value = e.target.value;

    setTheme(value);

    addNotification({
      text: `Theme has been changed to ${value}.`,
      type: 'success',
      autohide: 2500,
      position: 'top-right'
    });
  };

  const onLocaleUpdate = (e) => {
    const value = e.target.value;

    locale.set(value);

    addNotification({
      text: `Language has been changed to ${LOCALES[value]}.`,
      type: 'success',
      autohide: 2500,
      position: 'top-right'
    });
  };

  $: languages = $locales.map((value) => ({ label: LOCALES[value] || value, value }));
  $: themess = Object.keys($themes).map((value) => ({ label: $themes[value].meta.name, value }));
  $: console.log($themes);
</script>

<div class="page">
  <Loader show={!loaded} />

  <div class="page-header">
    <div class="row">
      <div class="col-md-6">
        <h1>{$format('label.SETTINGS')}</h1>
      </div>
      <div class="col-md-6 text-align--right" />
    </div>
  </div>

  <div class="page-content">
    <Container fluid class="pad--top--xxlarge pad--bottom--xxlarge">
      <h2>{$format('label.GENERAL')}</h2>
      <Row>
        <Col>
          <div class="field">
            <div>{$format('label.LANGUAGE')}</div>
            <label class="select">
              <select on:change={onLocaleUpdate}>
                {#each languages as language}
                  <option value={language.value} selected={$locale === language.value}>{language.label}</option>
                {/each}
              </select>
            </label>
          </div>
          <div class="field">
            <div>{$format('label.THEME')}</div>
            <label class="select">
              <select class="select" on:change={onThemeUpdate}>
                {#each themess as theme}
                  <option value={theme.value} selected={$currentThemeName === theme.value}>{theme.label}</option>
                {/each}
              </select>
            </label>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
</div>

<style lang="scss" src="./settings.scss"></style>
