import { polyfill as DnDTouchPolyfill } from 'mobile-drag-drop';

import App from './app/app.svelte';

import '@client/translations/i18n';

import './styles/styles.scss';

import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js';

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
);

import 'chartjs-adapter-date-fns';

DnDTouchPolyfill({
  tryFindDraggableTarget(event) {
    const element = event.target.closest('[draggable="true"]');

    if (element) {
      return element;
    }

    return null;
  }
});

new App({
  target: document.getElementById('app')
});
