<script>
  export let className = '';
  export let kind = 'primary';
  export let disabled = null;
  export let type = 'button';
  export let ref = null;
</script>

<button
  bind:this={ref}
  class="btn btn-{kind} {className}"
  aria-disabled={disabled}
  on:click
  {type}
  {disabled}
  {...$$props}>
    <slot />
</button>

<style lang="scss" src="./button.scss"></style>
