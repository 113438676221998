'use strict';

const debounce = (func, wait, immediate) => {
	let timeout;

	return function(...args) {
		const later = () => {
			timeout = null;

      if (!immediate) {
        func.apply(this, args);
      }
		};

		const now = immediate && !timeout;

		clearTimeout(timeout);

		timeout = setTimeout(later, wait);

    if (now) {
      func.apply(this, args);
    }
	};
};

module.exports = {
  debounce
};
