export const sidebar = {
  background: {
    color: '#2B35AF' //'#202345',//var(--color-white)'
  },
  border: '1px solid #222b9d',
  box: {
    shadow: 'inset 0 -15px 0 0 var(--sidebar-user-background-color)'
  },
  width: '280px',
  zindex: 10,
  divider: {
    color: '#222B9D'
  },
  header: {
    border: {
      bottom: '1px solid var(--sidebar-divider-color)'
    },
    height: '110px',
    image: {
      background: {
        _: 'url(https://colinks.s3.amazonaws.com/colinks-white.svg) no-repeat center center',
        size: '136px 36px'
      },
      height: '36px',
      width: '136px'
    },
    margin: '0 var(--margin-xlarge)',
    padding: 'var(--padding-xxlarge) 0',

    create: {
      background: '#fff',
      border: {
        _: '0',
        radius: '100%'
      },
      boxShadow: '0 1px 4px rgba(0, 0, 0, .1)',
      color: '#4653f6'
    }
  },

  section: {
    heading: {
      color: {
        _: '#7680ED',
        hover: 'var(--color-gray-900)',
        active: '#7680ED'
      },
      toggle: {
        icon: {
          color: {
            active: 'var(--color-gray-800)'
          }
        }
      },
      icon: {
        background: {
          color: {
            active: 'var(--color-blue-400)'
          }
        },
        color: {
          _: 'var(--color-gray-900)',
          active: 'var(--color-white)'
        }
      }
    },
    items: {
      border: {
        left: '2px solid var(--color-gray-300)'
      },
      active: {
        item: {
          color: 'var(--color-gray-900)'
        }
      }
    },
    item: {
      backgroundColor: {
        active: 'rgba(0, 0, 0, .1)', //'var(--color-active)',
        hover: 'rgba(0,0,0,.05)' //'#7680ED'
      },
      borderRadius: {
        _: 'var(--border-radius-base)',
        active: '20px',
        hover: '20px'
      },
      color: {
        _: 'var(--color-white)',
        hover: 'var(--color-gray-900)',
        active: '#9CA4FF'
      },
      fontSize: 'var(--font-size-base)'
    }
  },
  user: {
    background: {
      color: '#222b9d'
    },

    name: {
      color: 'var(--color-white)',
      fontSize: 'var(--font-size-base)',
      fontWeight: 'var(--font-weight-medium)'
    },

    company: {
      color: '#7680ED',
      fontSize: 'var(--font-size-medium)'
    },

    trigger: {
      backgroundColor: '#2B35AF',
      borderRadius: 'var(--border-radius-base)',
      color: 'var(--color-white)'
    }
  },
  collpased: {
    width: '101px',

    header: {
      image: {
        background: {
          _: 'url(https://colinks.s3.amazonaws.com/emblem-pill.svg) no-repeat center center',
          size: '44px 30px'
        },
        margin: '0 calc(var(--margin-large) + 2px)',
        height: '44px',
        width: '44px'
      }
    }
  }
};
