import { format } from 'date-fns';

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.className = 'chart-tooltip';
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0 || !chart.options.tooltips.enabled) {
    tooltipEl.style.opacity = 0;
    return;
  }

  const contents = tooltipEl;

  if (tooltip.body) {
    const title = tooltip.title || [];
    const colors = [];
    const lines = tooltip.body
      .map((b) => b.lines)
      .filter((line, index) => {
        const [, value] = line[0].split(': ');

        if (value !== '0') {
          colors.push(tooltip.labelColors[index]);
        }

        return value === '0' ? false : true;
      });
    const linesSorted = [...lines].sort();

    if (!linesSorted.length) {
      tooltipEl.style.opacity = 0;
      return;
    }

    while (contents.firstChild) {
      contents.firstChild.remove();
    }

    if (title.length) {
      const header = document.createElement('div');

      header.className = 'header';

      title.forEach((title) => {
        const div = document.createElement('div');
        const date = chart.options.scales.x?.type ? title.replace(', 12:00:00 a.m.', '') : title;
        const formatted = format(new Date(date), chart.options.tooltipDateFormat || 'MMMM d, yyyy');
        const text = document.createTextNode(formatted);

        div.appendChild(text);
        header.appendChild(div);
      });

      contents.appendChild(header);
    }

    const content = document.createElement('div');

    const flattened = lines.flat();

    linesSorted.forEach((body) => {
      const color = colors[flattened.indexOf(body[0])];
      const point = document.createElement('div');

      point.className = 'point';
      point.style.background = typeof color.backgroundColor === 'string' ? color.backgroundColor : color.borderColor;
      point.style.borderColor = color.borderColor;

      const item = document.createElement('div');
      item.className = 'item';

      const metric = document.createElement('div');
      const value = document.createElement('div');
      const [m, v] = body[0].split(': ');

      metric.className = 'metric';
      metric.innerHTML = m;

      value.className = 'value';
      value.innerHTML = v;

      const pair = document.createElement('div');

      pair.className = 'pair';
      pair.appendChild(metric);
      pair.appendChild(value);

      item.appendChild(point);
      item.appendChild(pair);

      content.appendChild(item);
    });

    contents.appendChild(content);

    if (tooltip.footer) {
      const footer = document.createElement('div');

      footer.innerHTML = tooltip.footer;

      contents.appendChild(footer);
    }
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.opacity = 1;

  const rect = tooltipEl.getBoundingClientRect();
  const x = tooltip.x || tooltip._eventPosition.x;
  const y = tooltip.y || tooltip._eventPosition.y;
  const containerWidth = parseInt(chart.canvas.style.width);

  tooltipEl.style.left = x + positionX + 24 + 'px';
  tooltipEl.style.top = y + positionY + 24 + 'px';

  if (containerWidth < x + rect.width) {
    tooltipEl.style.left = x - (positionX + 48) + 'px';
  }
};
