import { externalTooltipHandler } from './tooltip';

import merge from 'lodash/merge';

const updateTooltipShow = (chart, enabled) => {
  chart.options.tooltips.enabled = enabled;
  chart.update();
};

const configs = {
  defaults: {
    maintainAspectRatio: false,
    responsive: true,
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'touchend'],
    plugins: {
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
        events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'touchend']
      }
    },
    interaction: {
      axis: 'xy',
      mode: 'index',
      intersect: false
    },
    tooltips: {
      enabled: false
    },
    hover: {
      mode: 'index',
      intersect: false
    },
    onHover: function (e) {
      const { bottom, top, right, left } = this.chartArea;
      if (e.x >= left && e.x <= right && e.y <= bottom && e.y >= top) {
        updateTooltipShow(this, true);
      } else {
        updateTooltipShow(this, false);
      }
    }
  },
  bar: {},
  doughnut: {},
  pie: {},
  line: {
    plugins: {
      title: {
        display: true
      },
      legend: {
        display: false,
        align: 'end',
        position: 'bottom'
      }
    },
    interaction: {
      axis: 'xy',
      mode: 'index',
      intersect: false
    },
    tooltips: {
      enabled: false
    },
    elements: {
      line: {
        borderColor: 'rgb(0, 135, 255)',
        borderWidth: 1
      }
      // point: {
      //   radius: 0
      // }
    },
    hover: {
      mode: 'index',
      intersect: false
    },
    scales: {
      x: {
        display: true,
        type: 'time',
        time: {
          stepSize: 2
        },
        grid: {
          display: false,
          borderDash: [0],
          borderDashOffset: [0],
          color: 'rgba(0,0,0,.06)',
          zeroLineColor: 'rgba(0,0,0,.06  )'
        },
        ticks: {
          font: {
            size: 12
          }
        }
      },
      y: {
        ticks: {
          stepSize: 1,
          beginAtZero: true,
          showLabelBackdrop: true,
          callback: function (value) {
            return value.toLocaleString(undefined, { maximumFractionDigits: 0 });
          }
        },
        grid: {
          display: true,
          borderDash: [2],
          borderDashOffset: [0],
          drawBorder: false,
          color: 'rgba(0,0,0,.06)',
          zeroLineColor: 'rgba(0,0,0,.06)'
        }
      }
    }
  }
};

export const generateChartsConfig = (type, options = {}) => {
  return merge({}, configs.defaults, configs[type], options);
};
