<script>
  export let row = {};
</script>

<div style="text-align--center">
  <img
    class="porthole porthole--small"
    src={row.createdBy.imageUrl}
    alt={row.createdBy.name}
    title={row.createdBy.name}
    referrerpolicy="no-referrer"
  />
</div>

<style lang="scss">
  .porthole {
    border-radius: 100%;
    display: block;
    height: 64px;
    width: 64px;

    &--small {
      height: 40px;
      width: 40px;
    }
  }
</style>
