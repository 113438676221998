<script>
  import { getContext } from 'svelte';
  import { format } from 'svelte-i18n';

  import { Button } from '@client/components/button';
  import { getNotificationsContext } from '@client/components/notifications';
  import { Input } from '@client/components/input';
  import { Modal } from '@client/components/modal';
  import { Switch } from '@client/components/switch';

  import { LinksService } from '@client/services/links';

  const { addNotification } = getNotificationsContext();

  const { prefix } = getContext('config');

  export let show = false;
  export let editing = false;
  export let uniqueLinkName = false;
  export let currentUser = null;
  export let onSave = function () {};
  export let onClose = function () {};
  export let link = {
    title: '',
    link: '',
    private: false,
    source: ''
  };

  let formTitleInput;
  let submitButton;
  let linkSourceIsValid = false;
  let timer = null;

  let originalLink = null;

  const debounce = (fn, delay = 100) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  };

  const isLinkUnique = async () => {
    link.link = link.link.toLowerCase().replace(' ', '-');
    const response = await LinksService.get(link.link);
    uniqueLinkName = response === true || originalLink === link.link;
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      submitButton.click();
    }
  };

  const onCancel = () => {
    onClose();
    show = false;
  };

  const onUpdate = async () => {
    const { user } = currentUser;

    if (!editing) {
      link.link = link.link.toLowerCase();
      link.createdBy = currentUser;
    }

    link.updatedBy = user;

    if (editing && originalLink !== link.link) {
      await LinksService.delete({ link: originalLink, user: link.createdBy.user });
      await LinksService.create(link);
    } else {
      const action = editing ? 'update' : 'create';
      await LinksService[action](link);

      const text = $format('label.LINK_MODIFIED_SUCCESS', {
        values: {
          link: `${prefix}/${link.link}`,
          action: editing ? $format('label.UPDATED') : $format('label.CREATED')
        }
      });

      addNotification({
        text,
        type: 'success',
        autohide: 3000,
        position: 'top-right'
      });
    }

    onSave(true);

    originalLink = null;
    linkSourceIsValid = false;

    onCancel();
  };

  $: if (show && editing) {
    linkSourceIsValid = true;
  }

  $: if (show) {
    originalLink = null;
    setTimeout(() => formTitleInput.focus(), 0);
  }

  $: if (link.link.length && originalLink === null) {
    originalLink = link.link;
  }

  $: if (!show) {
    link = {
      title: '',
      link: '',
      private: false,
      source: ''
    };
  }

  $: isFormValid = link.title.length && link.source.length && link.link.length && linkSourceIsValid && uniqueLinkName;
</script>

<Modal bind:show>
  <div class="modals">
    <div class="modals--header">
      <h1>
        {#if editing}
          {$format('label.EDITING_LINK', { values: { link: `${prefix}/${link.link}` } })}
        {:else if link.link}
          {$format('label.CREATING_LINK', { values: { link: `${prefix}/${link.link}` } })}
        {:else}
          {$format('label.CREATE_NEW_LINK')}
        {/if}
      </h1>
    </div>
    <div class="modals--content">
      <div class="field">
        <label for="title">{$format('label.TITLE')}</label>
        <Input
          bind:ref={formTitleInput}
          type="text"
          id="title"
          class="fill"
          bind:value={link.title}
          on:keyup={onKeyUp}
        />
      </div>
      <div class="field">
        <label for="source">URL</label>
        <Input
          type="text"
          id="source"
          class="fill"
          bind:value={link.source}
          on:keyup={onKeyUp}
          bind:valid={linkSourceIsValid}
          pattern="^(http|https)://(.*)"
        />
      </div>
      <div class="field">
        <label for="link">{$format('label.LINK')}</label>
        <div class="link-input" class:disabled={editing}>
          <div class="link-input-prepend">{prefix}/</div>
          <Input
            type="text"
            id="link"
            class="fill"
            bind:value={link.link}
            on:keyup={(e) => debounce(isLinkUnique) && onKeyUp(e)}
          />
          {#if link.link?.length >= 2}
            <div class="indicator">
              {#if uniqueLinkName || (editing && link.link === originalLink)}
                <i class="links-icon-check" />
              {:else}
                <i class="links-icon-block" />
              {/if}
            </div>
          {/if}
        </div>
      </div>
      <div class="field">
        <label for="link">{$format('label.PRIVATE')}</label>
        <Switch bind:checked={link.private} />
        {#if link.private}
          <small>{$format('label.NOT_PUBLICLY_LISTED')}</small>
        {/if}
      </div>
    </div>
    <div class="modals--footer">
      <Button className="mr-md" bind:ref={submitButton} on:click={onUpdate} disabled={!isFormValid}>
        {#if !editing}
          {$format('label.SAVE')}
        {:else}
          {$format('label.UPDATE')}
        {/if}
      </Button>
      <Button kind="link" on:click={onCancel}>{$format('label.CANCEL')}</Button>
    </div>
  </div>
</Modal>

<style lang="scss" src="./edit.scss"></style>
