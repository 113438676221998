export const panel = {
  background: {
    color: 'var(--color-white)'
  },
  border: {
    radius: 'var(--border-radius-large)'
  },
  box: {
    shadow: '0 0 20px 0 rgba(0, 0, 0, .1)'
  },
  margin: 'auto',
  padding: {
    _: 'var(--padding-xxxlarge) var(--padding-xxxlarge)',
    screen: {
      sm: 'var(--padding-xxlarge) var(--padding-large)'
    }
  }
};
