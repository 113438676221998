<script>
  import { onMount, afterUpdate, setContext } from 'svelte';

  import { currentThemeName, currentThemeObject, themes as themesStore } from './theme.store';
  import { LocalStorage } from '@client/utils/local-storage';

  import { createStyle } from './createStyle';
  import { setTheme } from './hooks';

  export let key = 'theme';
  export let themes = {};
  export let theme = null;
  export let base = {};

  themesStore.set(themes);

  $: currentThemeObject.set(themes[$currentThemeName]);

  const cssVariables = createStyle(base, theme);
  const savedTheme = LocalStorage.get(key);

  if (savedTheme === undefined) {
    LocalStorage.create(key, theme);
  }

  setContext('theme', {
    current: currentThemeName,
    setTheme
  });

  onMount(() => {
    if (theme && themes[theme]) {
      currentThemeName.set(theme);
    } else if (savedTheme && themes[savedTheme]) {
      currentThemeName.set(savedTheme);
    } else {
      currentThemeName.set('opensense');
    }

    return () => LocalStorage.update(key, $currentThemeName);
  });

  afterUpdate(() => {
    if (document) {
      document.documentElement.setAttribute('theme', $currentThemeName);
    }

    LocalStorage.update(key, $currentThemeName);
  });
</script>

<svelte:head>
  {@html cssVariables}
</svelte:head>

<slot />
