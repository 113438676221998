<script>
  import { onMount } from 'svelte';
  import { clickOutside } from '@client/actions/click-outside';
  import { QueryParams } from '@client/utils/query-params';

  export let search = '';

  let searchRef = null;
  let searchActive = false;
  let searchFocus = false;

  const onClickOutside = () => {
    if (!search.length) {
      searchActive = false;
    }

    searchFocus = false;
  };

  const onKeyUp = () => {
    if (!search.length) {
      QueryParams.delete('search');
    } else {
      QueryParams.set('search', search);
    }
  };

  const onClick = () => {
    searchActive = true;
    searchRef.focus();
  };

  const onClearSearch = () => {
    search = '';
    QueryParams.delete('search');
  };

  const initialize = () => {
    const params = QueryParams.get('search');

    if (params) {
      search = params;
      searchActive = true;
    } else {
      if (search.length) {
        QueryParams.set('search', search);
        searchActive = true;
      }
    }
  };

  onMount(initialize);
</script>

<div
  class="search-filter"
  class:active={searchActive}
  class:focus={searchFocus}
  on:keyup={onKeyUp}
  on:click={onClick}
  use:clickOutside={{ onClickOutside }}
>
  <i class="links-icon-search" />

  <input
    type="search"
    bind:this={searchRef}
    bind:value={search}
    on:blur={() => (searchFocus = false)}
    on:focus={() => (searchFocus = true)}
  />

  <i class="links-icon-x" class:hidden={!search.length} on:click={onClearSearch} />
</div>

<style lang="scss" src="./search.scss"></style>
