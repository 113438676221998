import { APITransport } from '@client/transports/api';

export const LinksService = {
  getAll(query = {}) {
    return APITransport.get({
      path: '/v1/links',
      query
    });
  },

  get(link) {
    return APITransport.get({
      path: `/v1/links/${link}`
    });
  },

  create({ createdBy, link, private: priv, source, title, updatedBy }) {
    return APITransport.post({
      path: '/v1/links',
      body: {
        link,
        title,
        source,
        private: priv,
        createdBy,
        updatedBy
      }
    });
  },

  delete({ link, user }) {
    return APITransport.delete({
      path: `/v1/links/${link}`,
      body: { user }
    });
  },

  update({ link, title, source, private: priv, updatedBy }) {
    return APITransport.put({
      path: `/v1/links/${link}`,
      body: {
        link,
        title,
        private: priv,
        source,
        updatedBy
      }
    });
  }
};
