import { APITransport } from '@client/transports/api';

export const ReportsService = {
  getPopularLinks({ days = 90, limit = 3 }) {
    return APITransport.get({
      path: '/v1/reports/views',
      query: {
        report: 'popularLinks',
        days,
        limit
      }
    });
  },
  getTrendingLinks({ days = 1, limit = 5 }) {
    return APITransport.get({
      path: '/v1/reports/views',
      query: {
        report: 'trendingLinks',
        days,
        limit
      }
    });
  },
  getViewsBySourceTypeOverTime({ days = 180 }) {
    return APITransport.get({
      path: '/v1/reports/views',
      query: {
        report: 'viewsBySourceTypeOverTime',
        days
      }
    });
  },
  getTopNUsers({ limit = 5 }) {
    return APITransport.get({
      path: '/v1/reports/users',
      query: {
        report: 'topNUsers',
        limit
      }
    });
  },
  getViews({ link, days = 30 }) {
    return APITransport.get({
      path: '/v1/reports/views',
      query: {
        link,
        days,
        report: 'views'
      }
    });
  },
  getViewsBySourceType() {
    return APITransport.get({
      path: '/v1/reports/views',
      query: {
        report: 'viewsBySourceType'
      }
    });
  }
};
