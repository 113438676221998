<script>
  import { onMount } from 'svelte';
  import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'sveltestrap';
  import { format } from 'svelte-i18n';

  import { QueryParams } from '@client/utils/query-params';

  export let totalPages;
  export let totalRecords;
  export let currentPage;
  export let perPage;
  export let onChangePage = () => {};
  export let onChangeRecords = () => {};

  let pages = [];

  const onPageChange = (page) => {
    if (page >= 0 && page < pages.length) {
      currentPage = page;
    } else {
      currentPage = 0;
    }

    QueryParams.set('page', currentPage + 1);

    onChangePage(currentPage);
  };

  const onPerPageChange = (total) => {
    if (perPage !== total) {
      perPage = total;
      onChangeRecords(perPage);
    }

    currentPage = 0;
    QueryParams.set('page', currentPage + 1);
    QueryParams.set('perpage', perPage);
  };

  const initialize = () => {
    const page = QueryParams.get('page');
    const perpage = QueryParams.get('perpage');

    if (perpage) {
      perPage = perpage;
    } else {
      QueryParams.set('perpage', perPage);
    }

    if (page) {
      currentPage = page - 1;
    } else {
      QueryParams.set('page', currentPage + 1);
    }
  };

  onMount(initialize);

  $: pages = Array.from({ length: Math.ceil(Number(totalRecords) / perPage) });
  $: recordsStart = Math.min(totalRecords, currentPage * perPage + 1);
  $: recordsEnd = Math.min(totalRecords, currentPage * perPage + perPage);
  $: totalRecords < perPage * currentPage && currentPage > 0 && onPageChange(currentPage);
</script>

<nav class="pagination" {...$$props}>
  {#if pages.length > 1}
    <ul>
      <li class="pagination-next">
        <button type="button" disabled={currentPage === 0} on:click={() => onPageChange(currentPage - 1)}>
          <i class="links-icon-chevron-left" />
        </button>
      </li>

      {#each pages as page, i}
        <li class="pagination-page" class:active={i === currentPage}>
          <button type="button" on:click={() => onPageChange(i)}>
            {i + 1}
          </button>
        </li>
      {/each}

      <li class="pagination-prev">
        <button type="button" disabled={currentPage + 1 === totalPages} on:click={() => onPageChange(currentPage + 1)}>
          <i class="links-icon-chevron-right" />
        </button>
      </li>
    </ul>
  {:else}
    <span />
  {/if}

  <div class="pagination-records">
    <span class="count">
      {@html $format('label.SHOWING_X_OF_X', {
        values: { start: recordsStart, end: recordsEnd, total: totalRecords }
      })}
    </span>
    <Dropdown>
      <DropdownToggle tag="div">
        {@html $format('label.X_PER_PAGE', {
          values: { total: perPage }
        })}
        <i class="links-icon-chevron-down" />
      </DropdownToggle>
      <DropdownMenu>
        {#each [5, 10, 25, 50, 100, 250, 500] as records}
          <DropdownItem active={perPage === records} on:click={() => onPerPageChange(records)}>
            {records}
          </DropdownItem>
        {/each}
      </DropdownMenu>
    </Dropdown>
  </div>
</nav>

<style lang="scss" src="./pagination.scss"></style>
