<script>
  import { link } from 'svelte-navigator';

  export let row = {};
</script>

<div class="user">
  <img
    class="porthole porthole--small"
    src={row.imageUrl}
    alt={row.name}
    title={`${row.name}\n${row.email}`}
    referrerpolicy="no-referrer"
  />
  <a href="links?users={row.user}" use:link>
    {row.name}
  </a>
</div>

<style lang="scss">
  .user {
    align-items: center;
    display: flex;
    gap: 16px;
  }

  .porthole {
    border-radius: 100%;
    display: block;
    height: 32px;
    margin-top: -6px;
    width: 32px;
  }
</style>
