<script>
  export let backdrop = false;
  export let backdropColor = 'rgba(255, 255, 255, .6)';
  export let show = false;
</script>

{#if show}
  {#if backdrop}
    <div class="loader-backdrop" style="background-color: {backdropColor};" />
  {/if}
  <div class="loader-wrapper">
    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24">
      <circle stroke-linecap="round" cx="12" cy="12" r="10" fill="#0000" stroke-width="3" stroke="#4653f6" />
    </svg>
  </div>
{/if}

<style lang="scss" src="./loader.scss"></style>
