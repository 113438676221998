<script>
  import { onMount } from 'svelte';
  import { clickOutside } from '@client/actions/click-outside';

  export let ref = null;
  export let show = null;
  export let height = 'auto';
  export let minWidth = '350px';
  export let maxWidth = '400px';

  const onClickOutside = () => (show = false);
  const onKeyUp = ({ key }) => key === 'Escape' && onClickOutside();

  onMount(() => {
    document.body.addEventListener('keyup', onKeyUp);

    return () => {
      document.body.removeEventListener('keyup', onKeyUp);
    };
  });
</script>

<div class="modal-mask" class:show />

<div
  bind:this={ref}
  class="modal"
  class:show
  style="max-width: {maxWidth}; min-width: {minWidth}; height: {height};"
  use:clickOutside={{ onClickOutside }}
>
  <slot />
</div>

<style lang="scss" src="./modal.scss"></style>
