import { get } from 'svelte/store';
import { createCSSVars } from './createCSSVars';
import { themes as themesStore } from './theme.store';

export const createStyle = (base = {}) => {
  const themes = get(themesStore);
  const baseVariables = createCSSVars(base);
  const themeVariableOverrides = [];

  for (let [themeName, themeValues] of Object.entries(themes)) {
    const themeVariables = createCSSVars(themeValues);

    themeVariableOverrides.push(`
      [theme='${themeName}'] {
        ${themeVariables}
      }
    `);
  }

  return `<style>
    :root {
      ${baseVariables}
    }

    ${themeVariableOverrides.join('')}
  </style>`;
};
