<script>
  import { Loader } from '@client/components/loader';

  let loading = false;
</script>

<div class="page">
  <Loader backdrop show={loading} />
  <div class="page-header">
    <div class="row">
      <div class="col-md-6">
        <h1>Integrations</h1>
      </div>
      <div class="col-md-6 text-align--right" />
    </div>
  </div>
</div>
