import { writable } from 'svelte/store';

import positions from './positions';

let notificationCount = 0;

const isNotificationValid = (notification) => {
  if (
    !notification ||
    !notification.text ||
    typeof notification.text !== 'string' ||
    !positions.includes(notification.position)
  ) {
    return false;
  }

  return true;
};

const addNotification = (notification, update) => {
  if (!isNotificationValid(notification)) {
    throw new Error('Notification object is not valid');
  }

  const { id = notificationCount++, autohide = +notification.autohide, ...rest } = notification;

  update((notifications) => {
    return [
      ...notifications,
      {
        id,
        autohide,
        ...rest
      }
    ];
  });
};

const removeNotification = (notificationId, update) =>
  update((notifications) => {
    return notifications.filter(({ id }) => id !== notificationId);
  });

const clearNotifications = (set) => set([]);

const createNotificationsStore = () => {
  const { subscribe, set, update } = writable([]);

  return {
    subscribe,
    addNotification: (notification) => addNotification(notification, update),
    removeNotification: (notificationId) => removeNotification(notificationId, update),
    clearNotifications: () => clearNotifications(set)
  };
};

export default createNotificationsStore();
