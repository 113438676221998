import { APITransport } from '@client/transports/api';

export const ViewsService = {
  record(link, user) {
    return APITransport.post({
      path: `/v1/views/${link}`,
      body: {
        source: 'app',
        user
      }
    });
  }
};
