import Axios, { CancelToken } from 'axios';
import Cookies from 'universal-cookie';

import { TransportUtils } from './utils';
import { CancelablePromise } from '@common/utils/promises';

const API = '/api';
const axiosHeaders = {};
const cookies = new Cookies();

const axios = TransportUtils.axiosCreate({
  baseURL: API,
  headers: {
    ...axiosHeaders
  }
});

const generateRequest =
  (method) =>
  ({ baseURL = API, body, headers, query, path, timeout }) => {
    const source = CancelToken.source();
    const accessToken = cookies.get('accessToken');
    const refreshToken = cookies.get('refreshToken');

    const request = axios
      .request({
        method,
        baseURL,
        url: path,
        data: body,
        params: {
          ...query
        },
        headers: {
          ...headers,
          'x-refresh-token': refreshToken,
          Authorization: `Bearer ${accessToken}`
        },
        timeout,
        cancelToken: source.token
      })
      .then(
        ({ headers, data }) => {
          if (headers.authorization) {
            cookies.set('accessToken', headers.authorization);
          }

          if (headers['x-refresh-token']) {
            cookies.set('refreshToken', headers['x-refresh-token']);
          }

          return data.response || true;
        },
        (thrown) => {
          if (!Axios.isCancel(thrown)) {
            const { response } = thrown;

            if (response.status === 401) {
              window.location.reload();
              return;
            }

            return {
              error: {
                ...response.data.error,
                status: response.status
              }
            };
          }
        }
      );

    return CancelablePromise(request, () => source.cancel('Canceled'));
  };

export const APITransport = {
  get: generateRequest('get'),
  post: generateRequest('post'),
  put: generateRequest('put'),
  delete: generateRequest('delete')
};
