<script>
  import { Menu } from '@client/components/menu';

  export let row = {};
  export let currentUser = {};
  export let edit = () => {};
  export let remove = () => {};
  export let report = () => {};
  export let visibility = () => {};

  let show = false;

  const getMenuItems = (link) => {
    const items = [
      {
        onClick: (e, link) => edit(link),
        type: 'icon',
        iconClass: 'links-icon-edit-2'
      },
      {
        onClick: (e, link) => remove(link),
        type: 'icon',
        iconClass: 'links-icon-trash'
      },
      {
        onClick: (e, link) => visibility(link),
        type: 'icon',
        iconClass: link.private ? 'links-icon-eye-off' : 'links-icon-eye'
      }
    ];

    if (link.viewCount > 0) {
      items.splice(2, 0, {
        onClick: (e, link) => report(link),
        type: 'icon',
        disabled: link.viewCount === 0,
        iconClass: 'links-icon-insights'
      });
    }

    return items;
  };
</script>

{#if currentUser.role === 'admin' || row.createdBy.user === currentUser.user}
  <Menu classNames="grid-actions-menu" bind:show source={row} items={getMenuItems(row)} horizontal>
    <button class="links-icon-more-vertical action-icon" class:active={show} on:click={() => (show = !show)} />
  </Menu>
{/if}

<style lang="scss">
  :global(.grid-actions-menu) {
    right: 20px;
    top: -12px !important;
  }

  .action-icon {
    border: 1px solid transparent;
    border-radius: var(--border-radius-base);
    color: var(--color-text);
    cursor: pointer;
    padding: var(--padding-small) var(--padding-xsmall);

    &.active,
    &:hover {
      background-color: var(--color-white);
      border-color: var(--color-gray-200);
    }
  }
</style>
