<script>
  import { setContext } from 'svelte';

  import Notification from './notification.svelte';
  import context from './notification.context';
  import store from './notification.store';
  import positions from './positions';

  setContext(context, store);
</script>

<slot />

<div class="notifications">
  {#each positions as position}
    <div class={`notifications-position-${position}`}>
      {#each $store as notification (notification.id)}
        {#if notification.position === position}
          <Notification {notification} />
        {/if}
      {/each}
    </div>
  {/each}
</div>

<style lang="scss" src="./notifications.scss"></style>
