import { version } from '@root/package.json';

const isObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]';

class LocalStorageDB {
  constructor(key) {
    if (!key) {
      throw new Error('No parameter key passed to constructor. Expected String but got undefined');
    }

    this.STORE_KEY = key;
    this.store = window.localStorage;

    const isStorageCreated = this.store.getItem(this.STORE_KEY);

    if (!isStorageCreated) {
      this.store.setItem(this.STORE_KEY, JSON.stringify({}));
    }
  }

  create(key, data) {
    const storeData = this.get();
    const existingData = storeData[key];

    if (existingData) {
      if (existingData instanceof Array) {
        existingData.push(data);
        Object.assign(storeData[key], existingData);
      } else {
        storeData[key] = [existingData];
        storeData[key].push(data);
        this.store.setItem(this.STORE_KEY, JSON.stringify(storeData));
      }
    } else {
      const newData = {};
      newData[key] = data;
      Object.assign(storeData, newData);
    }

    this.store.setItem(this.STORE_KEY, JSON.stringify(storeData));
  }

  get(key = null, pos = null) {
    const storeData = JSON.parse(this.store.getItem(this.STORE_KEY));

    if (pos === null && key === null) {
      return storeData;
    } else if (key && !pos) {
      return storeData[key];
    }

    return storeData[key][pos];
  }

  update(key, update, pos = null) {
    const storeData = this.get();

    if (pos === null) {
      if (isObject(update)) {
        storeData[key] = Object.assign(storeData[key], update);
      } else {
        storeData[key] = update;
      }
    } else {
      storeData[key][pos] = update;
    }

    this.store.setItem(this.STORE_KEY, JSON.stringify(storeData));
  }

  remove(key, pos = null) {
    let storeData = this.get();

    if (!key) {
      storeData = {};
    }

    if (pos === null) {
      delete storeData[key];
    } else if (storeData[key] instanceof Array) {
      storeData[key].splice(pos, 1);
    } else {
      delete storeData[key][pos];
    }

    this.store.setItem(this.STORE_KEY, JSON.stringify(storeData));

    return true;
  }
}

export const LocalStorage = new LocalStorageDB('colinks');

export const manageCache = () => {
  const lastVersion = LocalStorage.get('version');

  if (lastVersion === undefined) {
    LocalStorage.create('version', version);
  } else {
    if (lastVersion !== version) {
      setTimeout(() => {
        window.localStorage.clear();
        window.location.reload();
      }, 250);
    }
  }
};
