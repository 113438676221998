export const dropdown = {
  menu: {
    background: {
      color: 'var(--color-white)'
    },
    color: 'var(--color-text)',
    border: {
      _: '1px solid var(--border-color)',
      radius: 'var(--border-radius-large)'
    },
    box: {
      shadow: '0 0 20px rgba(0,0,0, .1)'
    },
    font: {
      size: 'var(--font-size-base)'
    },
    padding: 'var(--padding-small) 0',
    zindex: 1000,

    item: {
      background: {
        color: {
          active: 'var(--color-white)',
          hover: 'var(--color-gray-100)'
        }
      },
      border: {
        radius: 'var(--border-radius-base)'
      },
      color: {
        active: 'var(--color-text)'
      },
      font: {
        size: '13px',
        weight: {
          active: 'var(--font-weight-medium)'
        }
      },
      padding: 'var(--padding-large) var(--padding-xlarge)',

      divider: {
        border: '1px solid #f1f1f1',
        margin: 'var(--margin-small) 0'
      }
    }
  }
};
