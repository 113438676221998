export const pill = {
  background: {
    color: 'var(--color-gray-200)'
  },
  border: {
    radius: '20px'
  },
  color: 'var(--color-gray-800)',
  font: {
    size: 'var(--font-size-medium)'
  },
  padding: 'var(--padding-base) var(--padding-base) var(--padding-base) calc(var(--padding-base) + 4px)',

  icon: {
    color: {
      _: 'var(--color-gray-700)',
      hover: 'var(--color-gray-900)'
    },
    font: {
      size: 'var(--font-size-large)'
    },
    margin: '0 0 0 var(--margin-small)'
  }
};
