<script>
  import { format } from 'svelte-i18n';
  import { link } from 'svelte-navigator';

  import { Loader } from '@client/components/loader';
  import { Chart } from '@client/components/charts';

  import { ReportsService } from '@client/services/reports';

  import { generateDatesInRange } from '@client/utils/dates';

  import COLORS from '@client/utils/collections/colors.json';

  const colors = [...COLORS];

  let loading = false;
  let chartData = {};
  let users = [];
  let trending = [];
  let popular = [];
  let noViewSource = false;

  const getViewsBySource = async () => {
    noViewSource = false;

    const views = await ReportsService.getViewsBySourceType();
    const labels = [];
    const data = [];

    if (!views.length) {
      noViewSource = true;
      return;
    }

    views.forEach((view) => {
      const source = view._id.source ? view._id.source : 'Unknown';
      labels.push(source[0].toUpperCase() + source.slice(1));
      data.push(view.total);
    });

    chartData = {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)', '#46BFBD'],
          hoverBackgroundColor: ['rgb(255, 99, 102)', 'rgb(54, 162, 205)', 'rgb(255, 205, 0)', '#5AD3D1']
        }
      ]
    };
  };

  const getTop10Users = async () => {
    users = await ReportsService.getTopNUsers({ limit: 5 });
  };

  const getTrendingLinks = async () => {
    const response = await ReportsService.getTrendingLinks({ days: 1, limit: 3 });
    trending = response.sort((a, b) => b.total - a.total || a.date - b.date);
  };

  const getPopularLinks = async () => {
    const response = await ReportsService.getPopularLinks({ days: 90, limit: 3 });
    popular = response.sort((a, b) => b.total - a.total || a.date - b.date);
  };

  let noViewHistory = false;
  let viewsOverTime = {};
  let viewsOverTimeOptions = {};
  let yAxisMax = 0;

  const getViewsBySourceTypeOverTime = async () => {
    noViewHistory = false;

    const views = await ReportsService.getViewsBySourceTypeOverTime({ days: 30 });

    if (!views.length) {
      noViewHistory = true;
      return;
    }

    const startDate = new Date(new Date().setDate(new Date().getDate() - 30));
    const endDate = new Date();
    const days = generateDatesInRange(startDate, endDate);
    const datasets = views.map(({ _id: label, data: views }) => {
      const color = colors.shift();
      const data = [];
      const dates = views.reduce((acc, view) => {
        if (acc[view._id]) {
          ++acc[view._id].total;
        } else {
          acc[view._id] = {
            total: 1
          };
        }

        return acc;
      }, {});

      days.forEach((day) => {
        if (dates[day]) {
          data.push(dates[day].total);
          yAxisMax = Math.max(yAxisMax, dates[day].total);
        } else {
          data.push(0);
        }
      });

      return {
        label,
        data,
        fill: 'origin',
        backgroundColor: color,
        borderColor: 'rgba(0,0,0,0)',
        tension: 0.1
      };
    });

    viewsOverTime = {
      labels: days,
      datasets
    };

    viewsOverTimeOptions = {
      elements: {
        line: {
          borderColor: 'rgb(0, 135, 255)',
          borderWidth: 0
        },
        point: {
          radius: 0
        }
      },
      scales: {
        y: {
          max: yAxisMax + 1
        }
      },
      tooltipDateFormat: 'eee, MMM d, yyyy'
    };
  };

  getViewsBySource();
  getTop10Users();
  getTrendingLinks();
  getPopularLinks();
  getViewsBySourceTypeOverTime();
</script>

<div class="page insights">
  <Loader backdrop show={loading} />
  <div class="page-header">
    <div class="row">
      <div class="col-md-12">
        <h1>{$format('label.INSIGHTS')}</h1>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12">
        <div class="dashboard">
          <div class="activity-chart">
            <h2>{$format('label.ACTIVITY')} <small class="time-period">30 {$format('label.DAYS')}</small></h2>
            {#if !noViewHistory}
              <Chart type="line" bind:data={viewsOverTime} bind:options={viewsOverTimeOptions} />
            {:else}
              <div class="charts empty">
                <i class="links-icon-bar-chart-2" />
                <div>{$format('label.NO_VIEW_ACTIVITY')}</div>
              </div>
            {/if}
          </div>
          <div class="views-chart">
            <h2 class="text-center">{$format('label.VIEWS_BY_SOURCE')}</h2>
            {#if !noViewSource}
              <Chart type="doughnut" bind:data={chartData} />
            {:else}
              <div class="charts empty">
                <i class="links-icon-bar-chart-2" />
                <div>{$format('label.NO_VIEW_SOURCE')}</div>
              </div>
            {/if}
          </div>
          <div class="right-column">
            <section>
              <h2>{$format('label.TOP_USERS')}</h2>
              {#if users.length}
                <ul class="top-users">
                  {#each users as user}
                    <li>
                      <a href="links?users={user.user}" use:link>
                        <img src={user.imageUrl} width="48" alt={user} referrerpolicy="no-referrer" />
                        <div>
                          <div class="name">{user.name}</div>
                          <div class="total small">{user.total} {$format('label.LINKS')}</div>
                        </div>
                      </a>
                    </li>
                  {/each}
                </ul>
              {:else}
                <div class="empty">
                  <i class="links-icon-users" />
                  <div>{$format('label.NO_TOP_USERS')}</div>
                </div>
              {/if}
            </section>
            <section>
              <h2>
                {$format('label.TRENDING_LINKS')}
                <small class="time-period">24 {$format('label.HOURS')}</small>
              </h2>
              {#if trending.length}
                <ul class="top-links">
                  {#each trending as link}
                    <li>
                      <a href="http://co/{link._id}" target="_blank">
                        <i class="links-icon-trending-up" />
                        <div>
                          <div class="name">{link._id}</div>
                          <div class="total small">
                            {$format('label.X_VIEWS_TOTAL', { values: { count: link.total } })}
                          </div>
                        </div>
                      </a>
                    </li>
                  {/each}
                </ul>
              {:else}
                <div class="empty">
                  <i class="links-icon-trending-up" />
                  <div>{$format('label.NO_TRENDING_LINKS')}</div>
                </div>
              {/if}
            </section>
            <section>
              <h2>
                {$format('label.POPULAR_LINKS')}
                <small class="time-period">90 {$format('label.DAYS')}</small>
              </h2>
              {#if popular.length}
                <ul class="top-links">
                  {#each popular as link}
                    <li>
                      <a href="http://co/{link._id}" target="_blank">
                        <i class="links-icon-star" />
                        <div>
                          <div class="name">{link._id}</div>
                          <div class="total small">
                            {$format('label.X_VIEWS_TOTAL', { values: { count: link.total } })}
                          </div>
                        </div>
                      </a>
                    </li>
                  {/each}
                </ul>
              {:else}
                <div class="empty">
                  <i class="links-icon-star" />
                  <div>{$format('label.NO_POPULAR_LINKS')}</div>
                </div>
              {/if}
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss" src="./insights.scss"></style>
